import { NextPage } from 'next'
import { useAuth } from 'helpers/hooks/useAuth/useAuth'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { StyledIndex } from '~pages/index.styles'

const Index: NextPage = () => {
  const { token } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (token) {
      router.push('/home')
    } else {
      router.push('/login')
    }
  }, [])

  return <StyledIndex />
}

export default Index
